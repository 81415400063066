<template>
  <section class="agreement-edit-view-handover">
    <div
      v-if="hasLegacyHandover"
      class="bg-white shadow-lg rounded-lg p-4 sm:px-8 pb-16"
    >
      <img alt="img" class="w-52 m-auto" src="public/illustrations/list-task.svg" />

      <div
        v-if="agreementFromFirebase?.handover?.status === oldHandoverStatuses.COMPLETE"
        class="my-6 text-center m-auto max-w-2xl"
      >
        {{ $t('agreement.landlord_dialog.handover_needs_confirmation') }}
      </div>

      <div
        v-else-if="
          agreementFromFirebase?.handover?.status === oldHandoverStatuses.ACCEPTED
        "
        class="my-6 text-center m-auto max-w-2xl"
      >
        {{ $t('agreement.landlord_dialog.handover_confirmed') }}
      </div>

      <div
        v-else-if="
          agreementFromFirebase?.handover?.status === oldHandoverStatuses.DECLINED
        "
        class="my-6 text-center m-auto max-w-2xl"
      >
        {{ $t('agreement.landlord_dialog.handover_declined') }}
      </div>

      <div v-else>
        <h2 class="text-1.5xl my-6 text-center m-auto max-w-2xl">
          {{ $t('agreement.handover_dialog.prepare_handover_act') }}
        </h2>
        <p class="my-6 text-center m-auto max-w-2xl">
          {{ $t('agreement.handover_dialog.prepare_handover_act_description') }}
        </p>

        <div class="my-4">
          <div class="flex gap-4 text-xl my-2">
            <r-icon class="text-rendin-500" icon-name="ballot-check" />
            <h3>
              {{ $t('agreement.handover_dialog.preparation') }}
            </h3>
          </div>
          {{ $t('agreement.handover_dialog.preparation_text') }}
        </div>
        <div class="my-4">
          <div class="flex gap-4 text-xl my-2">
            <r-icon class="text-rendin-500" icon-name="file-check" />
            <h3>
              {{ $t('agreement.handover_dialog.finalising') }}
            </h3>
          </div>
          {{ $t('agreement.handover_dialog.finalising_text') }}
        </div>
      </div>

      <div class="mt-4 md:mt-8 text-center">
        <r-button
          :label="
            isLegacyHandoverCompleted
              ? $t('buttons.view_handover_act')
              : $t('agreement.handover_dialog.prepare_handover')
          "
          target="_blank"
          :to="appHandoverLink"
          width="wide"
        />
      </div>

      <div class="bg-peach-100 rounded-lg p-4 mt-8">
        <div class="text-center">
          <span class="font-bold">{{ $t('agreement.handover_dialog.pro_tip') }}</span>
          {{ $t('agreement.handover_dialog.use_mobile_app') }}
        </div>
        <div
          v-if="
            getGlobalAppButtonLinksAndImages &&
            getGlobalAppButtonLinksAndImages?.bloks?.AppButtonBlok
          "
          class="max-w-xs mx-auto mt-4"
        >
          <AppButtonBlok :blok="getGlobalAppButtonLinksAndImages.bloks.AppButtonBlok" />
        </div>
      </div>
    </div>
    <div v-else>
      <handover-status-view
        :agreement-handover="agreementHandover"
        :agreement-id="agreementId"
        :app-handover-link="appHandoverLink"
        :is-tenant-view="isTenantView"
        @open-handover-edit="$emit('openHandoverEdit')"
        @open-handover-protocol="$emit('openHandoverProtocol')"
      />
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import {
  oldHandoverStatuses,
  handoverStatuses,
  handoverCompletedStatuses,
} from '~/utils/constants';
import HandoverStatusView from '~/components/section/handover/HandoverStatusView';

export default {
  name: 'AgreementEditViewHandover',
  components: {
    HandoverStatusView,
  },

  props: {
    agreementId: {
      type: String,
      default: null,
    },
    agreementFromFirebase: {
      type: Object,
      default: null,
    },
    agreementHandover: {
      type: Object,
      default: null,
    },
    isTenantView: {
      type: Boolean,
      default: true,
    },
    validate: {
      type: Number,
      default: null,
    },
  },

  emits: [
    'openHandoverProtocol',
    'openHandoverEdit',
    'agreementHandoverFormValidation',
  ],

  data: () => {
    return {
      oldHandoverStatuses,
    };
  },

  computed: {
    ...mapGetters({
      getGlobalAppButtonLinksAndImages:
        'storyBlokGlobals/getGlobalAppButtonLinksAndImages',
    }),

    appHandoverLink() {
      const appLink = `handover?agreementId=${this.agreementFromFirebase.id}`;
      const encodedRedirect = encodeURIComponent(appLink);
      return `/nstbc/login?mode=alta&redirect=${encodedRedirect}`;
    },

    hasLegacyHandover() {
      return !!this.agreementFromFirebase?.handover?.status;
    },

    isLegacyHandoverCompleted() {
      return handoverCompletedStatuses.includes(
        this.agreementFromFirebase?.handover?.status,
      );
    },
  },

  watch: {
    validate(val) {
      if (val) this.validateHandoverStatus();
    },
  },

  mounted() {
    this.actionGetGlobalAppButtonLinksAndImagesFromStoryblok();
    this.validateHandoverStatus();
  },

  methods: {
    ...mapActions({
      actionGetGlobalAppButtonLinksAndImagesFromStoryblok:
        'storyBlokGlobals/getGlobalAppButtonLinksAndImagesFromStoryblok',
    }),

    validateHandoverStatus() {
      let validationErrorForTabHeadline = '';

      if (!this.agreementFromFirebase?.handover) {
        const statusToHeadlineMap = {
          [handoverStatuses.DRAFT]: this.$t(
            'agreement.handover_v2.handover_tab_headline.draft',
          ),
          [handoverStatuses.READY_TO_SEND]: this.$t(
            'agreement.handover_v2.handover_tab_headline.ready_to_send',
          ),
          [handoverStatuses.COMPLETED]: this.$t(
            'agreement.handover_v2.handover_tab_headline.completed',
          ),
          [handoverStatuses.FINISHED]: this.$t(
            'agreement.handover_v2.handover_tab_headline.finished',
          ),
        };

        validationErrorForTabHeadline =
          statusToHeadlineMap[this.agreementHandover?.handover?.status] ||
          this.$t('agreement.handover_v2.handover_tab_headline.null');

        const result = {
          success:
            this.agreementHandover?.handover?.status === handoverStatuses.FINISHED,
          error: validationErrorForTabHeadline,
        };

        this.$emit('agreementHandoverFormValidation', result);
      } else {
        // Handover in legacy structure
        const statusToHeadlineMap = {
          [oldHandoverStatuses.DRAFT]: this.$t(
            'agreement.landlord_dialog.handover_draft',
          ),
          [oldHandoverStatuses.COMPLETE]: this.$t(
            'agreement.landlord_dialog.handover_needs_confirmation',
          ),
          [oldHandoverStatuses.ACCEPTED]: this.$t(
            'agreement.landlord_dialog.handover_confirmed',
          ),
          [oldHandoverStatuses.DECLINED]: this.$t(
            'agreement.landlord_dialog.handover_declined',
          ),
        };

        validationErrorForTabHeadline =
          statusToHeadlineMap[this.agreementHandover?.handover?.status] ||
          this.$t('agreement.label.handover_tab_text');

        const result = {
          success:
            this.agreementFromFirebase?.handover?.status ===
            oldHandoverStatuses.ACCEPTED,
          error: validationErrorForTabHeadline,
        };

        this.$emit('agreementHandoverFormValidation', result);
      }
    },
  },
};
</script>
